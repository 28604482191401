import useLocalStorage from 'hooks/useLocalStorage';
import { NextPage } from 'next';
import { signIn, useSession } from 'next-auth/react';
import { useEffect } from 'react';

const SignIn: NextPage = () => {
  const { status } = useSession();
  const { setValue: setDismissedBannerId } = useLocalStorage('dismissedBannerId', null);

  useEffect(() => {
    setDismissedBannerId(null);
    if (status != 'authenticated') signIn('azureb2c', { callbackUrl: `/` });
  });

  return <></>;
};

export default SignIn;
