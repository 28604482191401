import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const useLocalStorage = <T>(
  name: string,
  fallbackValue: T,
): { value: T; setValue: Dispatch<SetStateAction<T>> } => {
  const [value, setValue] = useState<T>(fallbackValue);

  useEffect(() => {
    const stored = localStorage.getItem(name);
    setValue(stored ? JSON.parse(stored) : fallbackValue);
  }, []);

  useEffect(() => {
    localStorage.setItem(name, JSON.stringify(value));
  }, [value]);

  return { value, setValue };
};

export default useLocalStorage;
